/* Global */
/* namesapce: app */

h1,h2,h3,h4,h5,p,span {
  font-family:"poppins"
}

:root {
  --primary-color:#07A197;
}

#body {
  background-color:#eff2f5 !important;
}
.app-footer {
  text-align: center;
  height: 72px;
}

.app-center {
  text-align: center;
}

/* namesapce: editable */
.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}

/* Admin Template */
/* namespace: adm */
#admin-template .logo {
  width: 100%;
  float: left;
}

#admin-template .logo img {
  width: 100%;
  height: 100vh;
}

#admin-template .admin-content {
  min-height: calc(100vh - 72px);
  width: 100%;
  height: 120vh;
}

/* Front Template */
/* namespace: front */
#front-template .logo {
  width: 120px;
  float: left;
}

#front-template .logo img {
  width: 100%;
}

#front-template .front-content {
  min-height: calc(100vh - 72px - 65px);
  width: 100%;
  /* max-width: 1200px; */
  margin: auto;
  padding: 20px 20px;
}

/* Center Template */
/* namespace: cen */
#center-template .cen-content {
  height: calc(100vh - 72px);
}

#center-template .cen-login {
  height: 100%;
  padding: 10px;
  background-color: #F7F7FC;
}

.bagLoc {
  /* height: 60px; */
  /* justify-content: center; */
  /* align-items: center; */
  min-height: 50px;
  font-size: 14px;
  line-height: 20px;
  /* -webkit-box-shadow: 0 15px 10px rgba(0,0,0,0.1);
  -moz-box-shadow: 0 15px 10px rgba(0,0,0,0.1); */
  /* box-shadow: 0 15px 10px rgba(0,0,0,0.1); */
  box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.1);
  /* text-align: center; */
  margin: 3px;
  background: white;
  border-radius: 4px;
  padding: 7px;
  /* display: flex; */
}

.ant-menu-item-selected {
  /* background-color: #B039CC !important; */
  background: #07A197 !important;

}

.ant-layout-sider {
  background-color: rgb(104, 104, 104) !important;
  flex: 0 0 300px !important;
  max-width: 400px !important;
  width: 300px !important;
  min-width: 200px !important;
}

.ant-input {


  /* Auto layout */

  padding: 8px 16px;
  /* width: 721px; */
  border-radius: 12px !important;

  /* Inside auto layout */



}



.ant-btn {
  /* box-sizing: border-box;
  display: flex; */
  /* flex-direction: column;
  align-items: flex-start;
  padding: 8px 12px 8px 16px;
  gap: 16px;
  height: 48px; */

  /* theme1/Inputs/Default bg */

  background: #FFFFFF;
  /* theme1/Border/Secondary */

  border: 2px solid #D9DBE9;
  border-radius: 8px !important;

  /* Inside auto layout */

  /* flex: none;
  order: 1;
  flex-grow: 0; */
}


.ant-table-cell {
  background-color: #FFFFFF !important;
}

.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.box {
  position: relative;
  width: 70%;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 16px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
}

.close-icon {
  content: 'x';
  cursor: pointer;
  position: fixed;
  right: calc(15% - 30px);
  top: calc(100vh - 85vh - 33px);
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}

.close-iconn {
  content: 'x';
  cursor: pointer;
  position: fixed;
  right: calc(35% - 10px);
  top: calc(100vh - 85vh - 33px);
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 10px;
}

.ant-menu-item-selected {
  /* background-color: #B039CC !important; */
  background: #07A197 !important;

}

.ant-layout-sider {
  background-color: rgb(104, 104, 104) !important;
  flex: 0 0 300px !important;
  max-width: 400px !important;
  width: 300px !important;
  min-width: 200px !important;
}


.status-popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}


.status-box {
  position: relative;
  width: 25%;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 16px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
}

.loading-list-box {
  position: relative;
  width: 40%;
  margin: 0 auto;
  height: 40%;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 16px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
}

.close-icon {
  content: 'x';
  cursor: pointer;
  position: fixed;
  right: calc(15% - 30px);
  top: calc(100vh - 85vh - 33px);
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}

.status-close-icon {
  content: 'x';
  cursor: pointer;
  position: fixed;
  right: calc(35% - 10px);
  top: calc(100vh - 85vh - 33px);
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}

.ant-picker-range-separator {
  align-items: center;
  padding: 0 0px !important;
  line-height: 1;
}


.close-icon {
  content: 'x';
  cursor: pointer;
  position: fixed;
  right: calc(15% - 30px);
  top: calc(100vh - 85vh - 33px);
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}

.ant-menu-item-selected {
  /* background-color: #B039CC !important; */
  background: #07A197 !important;

}

.ant-layout-sider {
  background-color: rgb(104, 104, 104) !important;
  flex: 0 0 300px !important;
  max-width: 400px !important;
  width: 300px !important;
  min-width: 200px !important;
}


.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1
}



.close-icon {
  content: 'x';
  cursor: pointer;
  position: fixed;
  right: calc(15% - 30px);
  top: calc(100vh - 85vh - 33px);
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}

.ant-menu-item-selected {
  /* background-color: #B039CC !important; */
  background: #07A197 !important;

}

.ant-layout-sider {
  background-color: rgb(104, 104, 104) !important;
  flex: 0 0 300px !important;
  max-width: 400px !important;
  width: 300px !important;
  min-width: 200px !important;
}

.ant-btn-primary {
  background: #07A197 !important;
  border-color: #07A197 !important;
}

.status-popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}
/* .ant-btn {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 12px 8px 16px;
  grid-gap: 16px;
  gap: 16px;
  width: 100; 
  height: 48px;
  background: #FFFFFF;
  border: 2px solid #D9DBE9;
  border-radius: 12px;
  flex: none;
  order: 1;
  flex-grow: 0;
} */

.status-box {
  position: relative;
  width: 25%;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 16px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
}

.close-icon {
  content: 'x';
  cursor: pointer;
  position: fixed;
  right: calc(15% - 30px);
  top: calc(100vh - 85vh - 33px);
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}

.status-close-icon {
  content: 'x';
  cursor: pointer;
  position: fixed;
  right: calc(35% - 10px);
  top: calc(100vh - 85vh - 33px);
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}

.ant-picker-range-separator {
  align-items: center;
  padding: 0 0px !important;
  line-height: 1;
}

.ant-picker-input>input {


  width: 10% !important;
  font-size: 1px !important;

}

.ant-picker-input {

  width: 0 !important;
}

.ant-picker-separator {

  width: 0em !important;
  height: 0px !important;
  font-size: 0px !important
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  border: 0 !important;
  border-radius: 20px !important;
}
.red {
  color:red;

}

.ant-dropdown-menu {
  position: relative;
  margin: 0;
  border-radius: 20 !important;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 16px !important;
  outline: none;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
}




.ant-table-thead > tr > th{
  position: relative;
  color: rgb(12 12 12 / 85%);
  font-weight: 500;
  text-align: left;
  background: #eff2f5 !important;
  border-bottom: 1px solid #eff2f5 !important;
  transition: background 0.3s ease;
  font-family: 'poppins';
 
}
.ant-table-tbody > tr > td {
  border-bottom: 10px solid #eff2f5 !important;
  transition: background 0.3s;
  font-family: 'poppins';
  
 
}

.ant-modal-content {
  border-radius: 20px !important;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background:var(--primary-color) !important;
}
button:disabled,
button[disabled]{
  background-color: #cccccc !important;
  color: #666666;
}